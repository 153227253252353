<template>
  <div :class="props.isInlineBlock ? `d-inline-block` : null" class="f3">
    <j-badge
        v-if="props.quantity > 0"
        :appearance="['badge-sm', 'bg-primary', 'f6 f96', 'm-l--3']"
        :text="String(1)" />
    <el-tooltip
        v-if="props.lightBoxEnable"
        class="box-item"
        :content="$t(`component.avatar.display`)"
        :placement="props.tooltipPlacement">
      <img @click="setLightBox(
              true,
              [getImage],
              0,
              props.lightBoxDownload
           )"
           :class="getAppearance.concat([props.class])"
           class="avatar-s"
           src="@/asset/img/core/img-background.png"
           alt="avatar"
           v-lazy:background-image="getImage" />
    </el-tooltip>
    <img v-else
         :class="getAppearance.concat([props.class])"
         class="avatar-s"
         src="@/asset/img/core/img-background.png"
         alt="avatar"
         v-lazy:background-image="getImage" />
  </div>
</template>

<script setup lang="ts">

import JBadge from "@/component/Badge.vue"

import {computed} from "vue"
import {useMitt} from "@/hook/useMitt"
import {EnumAvatarAdmin, EnumAvatarBillboard, EnumAvatarMenuHorizontal, EnumAvatarShepherd} from "@/enum/avatar";

const {
  setLightBox
} = useMitt()

const props = withDefaults(defineProps<{
  //編輯類型
  type?: number
  //傳入圖片位置
  url?: string | null
  //是否可以開啟lightbox
  lightBoxEnable?: boolean
  //lightbox是否可以下載 (lightBoxEnable需要true)
  lightBoxDownload?: boolean
  //外觀
  class?: string | null
  //角標數量
  quantity?: number
  //是否為內崁
  isInlineBlock?: boolean
  //tooltip出現的位置
  tooltipPlacement?: string
}>(),{
  type: EnumAvatarMenuHorizontal.HEAD,
  url: null,
  lightBoxEnable: false,
  lightBoxDownload: false,
  class: null,
  quantity: 0,
  isInlineBlock: false,
  tooltipPlacement: "top-start"
})

const getImage = computed(()=>{
  if (props.url !== null && props.url !== "") return props.url
  else if ([EnumAvatarAdmin.CLIENT, EnumAvatarAdmin.TAG, EnumAvatarAdmin.USER].includes(props.type)) return "/img/avatar/holder/avatar.png"
  else if (props.type===EnumAvatarAdmin.TEACH) return "/img/avatar/holder/teach.png"
  else if (props.type===EnumAvatarAdmin.CHURCH) return "/img/avatar/holder/my.jpg"
  else if (props.type===EnumAvatarAdmin.MY) return "/img/avatar/holder/default.png"
  else if (props.type===EnumAvatarAdmin.SOUL) return "/img/avatar/holder/default.png"
  else if (props.type===EnumAvatarAdmin.DAILY) return "/img/avatar/holder/default.png"
  else if (props.type===EnumAvatarAdmin.SOUL_CATEGORY) return "/img/avatar/holder/default.png"
  return "/img/avatar/holder/avatar.png"
})

//回傳外觀比例
const getAppearance = computed((): any[] => {
  if(props.type===EnumAvatarAdmin.CHURCH) return ['avatar-church']
  else if(props.type===EnumAvatarAdmin.UPLOAD) return ['rounded', 'avatar-upload']
  else if(props.type===EnumAvatarAdmin.GALLERY) return ['rounded', 'avatar-gallery']
  else if(props.type===EnumAvatarAdmin.USER) return ['rounded', 'avatar-user']
  else if(props.type===EnumAvatarAdmin.CLIENT) return ['rounded', 'avatar-client']
  else if(props.type===EnumAvatarAdmin.TAG) return ['rounded', 'avatar-tag']
  else if(props.type===EnumAvatarAdmin.MY) return ['avatar-my']
  else if(props.type===EnumAvatarAdmin.SOUL) return ['avatar-soul']
  else if(props.type===EnumAvatarAdmin.SOUL_CATEGORY) return ['avatar-soul', 'soul-category']
  else if(props.type===EnumAvatarAdmin.DAILY) return ['avatar-daily']
  else if([EnumAvatarAdmin.RECIPIENT, EnumAvatarAdmin.MEMBERSHIP].includes(props.type)) return ['avatar-recipient']
  else if(props.type===EnumAvatarAdmin.TEACH) return ['avatar-teach']
  else if(props.type===EnumAvatarBillboard.ENTRY) return ['rounded', 'avatar-billboard']
  else if(props.type===EnumAvatarBillboard.MENU) return ['rounded', 'avatar-billboard-menu']
  else if(props.type===EnumAvatarShepherd.FRIEND) return ['rounded', 'avatar-friend']
  else if([EnumAvatarAdmin.COURSE_ACCESS, EnumAvatarAdmin.COURSE_AUTHORIZE , EnumAvatarAdmin.COURSE_EXCEPTION].includes(props.type)) return ['rounded', 'avatar-course']
  return ['rounded', 'avatar-normal']
})

</script>

<style scoped lang="sass">
.f3
  position: relative
  .f6
    position: absolute !important
    right: 3px
    top: 2px
  .avatar-s
    background-repeat: no-repeat
    background-size: cover
    background-position: center center
    aspect-ratio: 1 / 1
    cursor: pointer
    border-radius: 0.275rem
    &.avatar-teach
      aspect-ratio: 670 / 1190
      height: 150px
      box-shadow: rgb(0 0 0 / 16%) 0 2px 4px, rgb(0 0 0 / 16%) 0 2px 4px
      background-size: cover
    &.avatar-church,
    &.avatar-my,
    &.avatar-soul,
    &.avatar-daily
      aspect-ratio: 690 / 388
      width: 100%
      box-shadow: rgb(0 0 0 / 16%) 0 2px 4px, rgb(0 0 0 / 16%) 0 2px 4px
      background-size: cover
      &.soul-category
        aspect-ratio: 280 / 160
    &.avatar-normal
      width: 40px
      height: 40px
      border-radius: 20px !important
    &.avatar-client,
    &.avatar-friend,
    &.avatar-billboard,
    &.avatar-billboard-menu,
    &.avatar-recipient,
    &.avatar-user,
    &.avatar-course
      width: 60px
      height: 60px
      border-radius: 30px !important
      box-shadow: rgb(0 0 0 / 16%) 0 2px 4px, rgb(0 0 0 / 16%) 0 2px 4px
    &.avatar-recipient
      width: 45px
      height: 45px
      border-radius: 22.5px !important
    &.avatar-billboard,
    &.avatar-billboard-menu,
    &.avatar-course
      width: 40px
      height: 40px
      border-radius: 20px !important
    &.avatar-tag
      width: 50px
      height: 50px
    &.avatar-upload
      width: 100px
      height: 100px
      box-shadow: rgb(0 0 0 / 16%) 0 2px 4px, rgb(0 0 0 / 16%) 0 2px 4px
    &.avatar-gallery
      width: 100%
      box-shadow: rgb(0 0 0 / 16%) 0 2px 4px, rgb(0 0 0 / 16%) 0 2px 4px
</style>
