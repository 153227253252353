export default {
    "搜尋名單": "搜尋名單",
    "搜尋範圍: 名字/郵件": "搜尋範圍: 名字/郵件",
    "族群名稱": "族群名稱",
    "請輸入族群名稱": "請輸入族群名稱",
    "所屬族群": "所屬族群",
    "請選擇所屬族群": "請選擇所屬族群",
    "關閉": "關閉",
    "確定": "確定",
    "最少需要選擇幾位族群成員": "最少需要選擇 %s 位族群成員",
    "最少需要選擇幾位託管人員": "最少需要選擇 %s 位託管人員",
    "最多只能選擇幾位託管人員": "最多只能選擇 %s 位託管人員",
    "尚未異動項目": "尚未異動項目",
    "成員種類1": "族群成員",
    "成員種類2": "託管人員",
    "可選擇幾個會友": "可選擇 <b class=\"cgBase\">%s</b> 個會友",
    "請選擇加入的項目": "請選擇加入的項目",
    "加入": "加入",
    "移除": "移除",
    "已選擇幾位族群成員": "已選擇 <b class=\"cgBase\">%s</b> 位族群成員",
    "已選擇幾位託管人員": "已選擇 <b class=\"cgBase\">%s</b> 位託管人員",
    "相關資訊": "相關資訊",
    "基本:": "基本:",
    "團報": "團報",
    "表單": "表單",
    "金流": "金流",
    "報名人員": "報名人員",
    "報名時間": "報名時間",
    "重設": "重設",
    "立即更新": "立即更新",
    "聚會開始時間": "聚會開始時間",
    "請選擇開始時間": "請選擇開始時間",
    "首次聚會開始時間": "首次聚會開始時間",
    "聚會結束時間": "聚會結束時間",
    "請選擇結束時間": "請選擇結束時間",
    "首次聚會結束時間": "首次聚會結束時間",
    "系統已經推播完成，無法編輯或刪除": "系統已經推播完成，無法編輯或刪除",
    "系統已經正在推播，無法編輯或刪除": "系統已經正在推播，無法編輯或刪除",
    "標題": "標題",
    "請輸入標題": "請輸入標題",
    "上架時間": "上架時間",
    "下架時間": "下架時間",
    "外部活動連結": "外部活動連結",
    "請輸入外部活動連結": "請輸入外部活動連結",
    "Youtube連結": "Youtube連結",
    "請輸入Youtube連結": "請輸入Youtube連結",
    "Google雲端MP3": "Google雲端MP3",
    "請輸入Google雲端MP3": "請輸入Google雲端MP3",
    "直播連結": "直播連結",
    "請輸入直播連結": "請輸入直播連結",
    "外部網站連結": "外部網站連結",
    "請輸入外部網站連結": "請輸入外部網站連結",
    "觀看": "觀看",
    "外連": "外連",
    "留言": "留言",
    "推播": "推播",
    "排程": "排程",
    "類型": "類型",
    "置頂": "置頂",
    "封面圖": "封面圖",
    "操作": "操作",
    "狀態": "狀態",
    "建立時間": "建立時間",
}
